import { onMounted, ref } from 'vue'
import sha256 from 'sha256'
import i18n from '@/i18n';
import JSEncrypt from 'jsencrypt'
import { parseBigInt } from 'jsencrypt/lib/lib/jsbn/jsbn';
import plugins from '@/plugins/plugins.js';
import dayjs from 'dayjs'
import ar from 'dayjs/locale/ar'
import _ from 'lodash'
console.log(ar)

export function fSha256(input) {
    return sha256(input)
}

export function fValidatePassword(value, callback) {
    /*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E] symbol regex
    * Password must be at least 8 characters and contain both alphabetic and numeric characters.
    */
    // const passwordRegex = /^\S*(?=\S{8,})(?=\S*\d)(?=\S*[a-zA-Z])(?=\S*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])\S*$/;
    // const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=\S{8,})(?!.*\s)/;
    if (!passwordRegex.test(value)) {
        throw new Error(i18n.global.t('passwordRule'));
    } else {
        return callback
    }
}

export function fValidateNickName(value,word,callback, limitArr){
    // 1.纯英文字母2.英文字母+空格
    const regex = /^[A-Za-z]+(\s+[A-Za-z]+)?$/
    if (!regex.test(value)) {
        throw new Error(i18n.global.t(word));
    } else if (limitArr) {
      if(limitArr[0] && value.trim()?.length < limitArr[0]) {
        throw new Error(i18n.global.t(word));
      } else if (limitArr[1] && value.trim()?.length > limitArr[1]) {
        throw new Error(i18n.global.t(word));
      } else {
        return callback
      }
    } else {
        return callback
    }
}
export function fValidateNumber(value, callback) {
    // 数字
    const regex = /^\d+$/
    if (!regex.test(value)) {
      throw new Error(i18n.global.t('PhoneRule'));
    } else {
      return callback;
    }
  }
export function formatDate(inputDate, displayComplete = false) {
    const current = new Date()
    const date = new Date(inputDate);
    const diffInDays = current.getDate() - date.getDate()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    // const seconds = String(date.getSeconds()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const formattedDate = displayComplete ? `${year}-${month}-${day} ${hours}:${minutes}` :
        diffInDays === 0 ? `${i18n.global.t('Today')}  ${hours}:${minutes}` :
            diffInDays === 1 ? `${i18n.global.t('Yesterday')}  ${hours}:${minutes}` : `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
}

// notification页面时间格式化
export function formatDateForNotification(inputDate) {
  const current = new Date()
  const date = new Date(inputDate);
  const diffInDays = current.getDate() - date.getDate()

  let month
  if(diffInDays === 0) {
    month = i18n.global.t('Today')
  } else if(diffInDays === 1) {
    month = i18n.global.t('Yesterday')
  } else {
    month = dayjs(date).locale(plugins.getLocal('lang')).format('MMMM Do YYYY')
  }

  return month + ', ' + dayjs(date).locale(plugins.getLocal('lang')).format('h:mm A')
}

function handleCode(d) {
    var b = d.toByteArray()
    var i = 0
    while (i < b.length && b[i] === 0) {
        ++i
    }
    ++i
    while (b[i] !== 0) {
        if (++i >= b.length) {
            return null
        }
    }
    var ret = ''
    while (++i < b.length) {
        var c = b[i] & 255
        if (c < 128) { // utf-8 decode
            ret += String.fromCharCode(c)
        } else if ((c > 191) && (c < 224)) {
            ret += String.fromCharCode(((c & 31) << 6) | (b[i + 1] & 63))
            ++i
        } else {
            ret += String.fromCharCode(((c & 15) << 12) | ((b[i + 1] & 63) << 6) | (b[i + 2] & 63))
            i += 2
        }
    }
    return ret
}
export function encrypt(publicKey, text) {
    const encryptor = new JSEncrypt()
    encryptor.setPublicKey(publicKey) // Set publicKey
    return encryptor.encrypt(text) // To encrypt
}
export function decrypt(publicKey, data) {
    const encrypt = new JSEncrypt()
    encrypt.setPublicKey(publicKey)
    // cuz 'JSEncrypt' does not support to decrypt by publicKey 
    // should customize function by self
    const rsaKey = encrypt.getKey()
    rsaKey.decrypt = function (ctext) {
        var c = parseBigInt(ctext, 16)
        var m = this.doPublic(c)
        if (m == null) {
            return null
        }
        // (this.n.bitLength() + 7) >> 3
        return handleCode(m)
    }
    return encrypt.decrypt(data)
}
export function transformAmount(value) {
    value = ('' + value) // Convert to a string
      .replace(/[^\d^.]+/g, '') // Filter out non numeric or non decimal points
      .replace(/^0+(\d)/, '$1') // If the first digit starts with 0 and is followed by a number, filter it out and take the number after it
      .replace(/^\./, '0.') // If the first digit of the input is a decimal point, replace it with 0 Implement automatic completion
      .match(/^\d*(\.?\d{0,2})/g)[0] || '' // The final matching result starts with a number, with only one decimal point, and can only have 0 to 2 decimal places after the decimal point
    return value;
}

// 是否从app跳转过来
export function isFromApp() {
  const excludePaths = ['/information', '/FAQ', '/aboutUs', '/privacy']
  const path = window.location.pathname
  const params = window.location.search

  return excludePaths.includes(path) && params === '?from=app'
}

import { isMobileOnly } from 'vue-device-detect'
export function useMobile() {
  const mobile = ref(false)

  function getPopupContainer(triggerNode) {
    if(mobile.value) {
      return document.body
    } else {
      return triggerNode.parentNode
    }
  }

  onMounted(() => {
    if(isMobileOnly) {
      mobile.value = true
      import('@/styles/mobile.scss')
    }
  })

  return {
    mobile,
    getPopupContainer
  }
}

export function loadScript(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')

    script.onload = () => resolve()

    script.onerror = () => reject(new Error(`Load script from ${url} failed`))

    script.src = url
    const head =
        document.head || document.getElementsByTagName('head')[0]
      ;(document.body || head).appendChild(script)
  })
}

// format date to history table

export function formatDateToTable(inputDate) {
  const date = new Date(inputDate);
  date.setUTCHours(date.getUTCHours() + 4);
  return _.replace(
    date.toLocaleDateString('en-US', { 
      timeZone: 'UTC', 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    }),
    ',', 
    ''
  );

}

// format date to memeber level
export function memberLevelDate(timestampUTC, timezoneOffsetHours) {
  // Parse the timestamp string.  Crucially, use the correct format.
  const date = new Date(timestampUTC);

  // Add the timezone offset.  This is crucial for accuracy.
  date.setHours(date.getHours() + timezoneOffsetHours);

  // Format the date as "MM/DD/YYYY".  Use a more robust method.
  const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}