const ar = {
  lendTitle: "منصة تأجير الأزياء",
  lendSubTitle: "استعِر أرقى القِطَع دوماً بلا حدود",
  lendQuestionTitle: "كيف يتم ذلك؟",
  lendQuestionMem: "اختر عضويتك",
  lendQuestionMemAns: `اختر فئة العضوية الخاصة بك لاستكشاف مجموعتنا الحصرية واختر القِطعة التي ترغب في استئجارها.`,
  lendQuestionCheck: "الدفع والتوصيل",
  lendQuestionCheckAns: `بمجرد تأكيد عضويتك والقِطعة التي ترغب في استئجارها، سنضمن لك توصيلها بسرعة مباشرة إلى باب منزلك.`,
  lendQuestionTime: "استمتع بميزة استبدال شهرية",
  lendQuestionTimeAns: `بعد شهر من الاستئجار، استمتع بخيار استبدال اختيارك بقطعة أخرى فاخرة.`,
  lendTierTitle: "اختر عضويتك في النادي",
  lendCurrency: "AED",
  lendMonthly: "Monthly",
  lendQuaterly: "Quaterly",
  lendSelect: "Select",
  lendAccess: "Access",
  lendGold: "Gold",
  lendPlatinum: "Platinum",
  lendDiamond: "Diamond",
  lendButton:
    "هذه الخدمة متاحة حصرياً للمقيمين في دولة الإمارات العربية المتحدة.",
  lendGoodsTitle: "استكشف مجموعتنا",
  lendGoodsTitleAll: "عرض الكل",
  lendGoodsFaq: "الأسئلة الشائعة",
  lendSave: "وفر",
  lendUpGrade: "ترقية إلى",
  lendSelectInfo: "اختر الفئة التي تناسبك",
  lenfMembership: "العضوية الحالية",
  lendPause: "العضوية متوقفة مؤقتاً",
  lendAssess: "وصول إلى",
  lendEnjoy: "استمتع",
  lendPer: "شهرياً",
  lendInitiationFee: "رسوم بدء العضوية",
  LendmemberShip: "العضوية",
  LendStatus: "الحالة",
  LendSwap: "الاستبدالات المتبقية",
  LendSwapHistory: "سجلُّ الاستبدالات",
  LendSubscription: "تاريخ الاشتراك",
  LendPayment: "تفاصيل الدفع",
  LendVerify: "تفاصيل التحقُّق",
  LendHistoryProduct: "المنتَج",
  LendHistoryDelivery: "حالة التوصيل",
  LendHistoryReturn: "حالة الإرجاع",
  LendHistoryTableDelivery: "Delivery on",
  LendHistoryTableReturn: "إرجاع القِطعة",
  LendSubscriptionTableProduct: "المنتَج",
  LendSubscriptionTableDate: "التاريخ",
  LendSubscriptionTableAmount: "الكمية",
  LendSubscriptionTableMethod: "الطريقة",
  LendMemberUpgrade: "ترقية",
  LendMemberPause: "إيقاف مؤقت",
  LendMemberCancel: "إلغاء",
  LendNextPay: "تاريخ الاستحقاق التالي هو",
  LendNextSwap: "الاستبدال التالي في",
  LendBecomeMember: "انضم إلى العضوية",
  LendNolevel: "انضم إلى المنصة!",
  LendLevelText: "فعّل عضويتك اليوم.",
  LendMemberHistory:'سجلُّ الاستبدالات',
  LendMemberSubscription: "تاريخ الاشتراك",
  LendMemberPayment: "تفاصيل الدفع",
  LendMemberVerify: "تفاصيل التحقُّق",
  LendCancelInfo: `لإلغاء اشتراكك، يرجى إرسال بريد إلكتروني لنا على membership@falcons.com حتى نتمكن من معالجة إلغاء اشتراكك وضمان عدم فرض أي رسوم إضافية عليك.`,
  LendCancelTitle: "إلغاء العضوية",
  LendPauseTitle: "إيقاف مؤقت للعضوية",
  LendPauseInfo: `لإيقاف اشتراكك مؤقتًا، يُرجى إرسال بريد إلكتروني إلى membership@falcons.com، وسنعمل على معالجة طلبك لضمان عدم فرض أي رسوم خلال فترة الإيقاف.`,
  LendBack:'عودة' 
};
const en = {
  lendTitle: "Lending Club",
  lendSubTitle: "Borrow endlessly on rotation.",
  lendQuestionTitle: "How does it work?",
  lendQuestionMem: "Select Your Membership",
  lendQuestionMemAns: `Select your membership tier to explore our exclusive collection and choose the item you'd like to rent.`,
  lendQuestionCheck: "Checkout & Delivery",
  lendQuestionCheckAns: `Once your membership and item to rent is confirmed, we will ensure its swift delivery directly to your doorstep.`,
  lendQuestionTime: "Enjoy Monthly Swaps",
  lendQuestionTimeAns: `After a month of renting, enjoy the option of swapping your selection for another exquisite piece.`,
  lendTierTitle: "Choose your Club Membership",
  lendCurrency: "AED",
  lendMonthly: "Monthly",
  lendQuaterly: "Quaterly",
  lendSelect: "Select",
  lendAccess: "Access",
  lendGold: "Gold",
  lendPlatinum: "Platinum",
  lendDiamond: "Diamond",
  lendButton: "This service is exclusively for the residents of UAE.",
  lendGoodsTitle: "EXPLORE OUR COLLECTION",
  lendGoodsFaq: "Frequently Asked Questions",
  lendGoodsTitleAll: "View All",
  lendFaq1: "What is Lending Club?",
  lendFaq1Ans:
    "Lending Club is a peer-to-peer lending platform that allows individuals to borrow money from each other on a fixed-term basis. It offers a wide range of loans, including personal loans, car loans, and student loans, and is available in over 100 countries.",
  lendFaq2: "How does Lending Club work?",
  lendSave: "SAVE",
  lendUpGrade: "Upgrade to",
  lendSelectInfo: "Select Your Tier",
  lenfMembership: "Current Membership",
  lendPause: "Membership Paused",
  lendAssess: "Assess",
  lendEnjoy: "Enjoy",
  lendPer: "per month",
  lendInitiationFee: "Initiation Fee",
  LendmemberShip: "MEMBERSHIP",
  LendStatus: "STATUS",
  LendSwap: "SWAP LEFT",
  LendMemberHistory:'Swap History',
  LendSwapHistory: "SWAP HISTORY",
  LendSubscription: "SUBSCRIPTION HISTORY",
  LendMemberSubscription: "Subscription History",
  LendPayment: "PAYMENT DETAILS",
  LendMemberPayment: "Payment Details",
  LendVerify: "VERIFICATION DETAILS",
  LendMemberVerify: "Verification Details",
  LendHistoryProduct: "Product",
  LendHistoryDelivery: "Delivery Status",
  LendHistoryReturn: "Return Status",
  LendHistoryTableDelivery: "Delivery on",
  LendHistoryTableReturn: "Return Item",
  LendSubscriptionTableProduct: "Product",
  LendSubscriptionTableDate: "Date",
  LendSubscriptionTableAmount: "Amount",
  LendSubscriptionTableMethod: "Method",
  LendMemberUpgrade: "UPGRADE",
  LendMemberPause: "PAUSE",
  LendMemberCancel: "CANCEL",
  LendNextPay: "Next due date at",
  LendNextSwap: "Next swaps at ",
  LendBecomeMember: "BECOME A MEMBER",
  LendNolevel: "Join the Club!",
  LendLevelText: "Activate your membership today.",
  LendCancelInfo: `To cancel your subscription, please email us at membership@falcons.com so we can process your cancellation to guarantee you'll no longer be charged for this subscription.`,
  LendCancelTitle: "CANCEL MEMBERSHIP",
  LendPauseTitle: "PAUSE MEMBERSHIP",
  LendPauseInfo: `To pause your subscription, please email us at membership@falcons.com, and we will process your request to ensure no further charges during the pause period.`,
  LendBack:'BACK' ,
  LendDownLevel: "Downgrading Membership Not Available",
  LendUnUpgrade:"Upgrades are available within the same billing cycle: monthly to monthly or quarterly to quarterly.",
  LendInactive:'Membership Cancellation'
};

export { ar, en };
